import { FC } from "react";
import { IWallet } from "../../../../api/wallets/types";
import { networkCurrencies, networkLogoSrcs, networkNames, networkPrecision } from "../../../../constants/networks";
import { bigintToNumber } from "../../../../utils/balance";
import { useNavigate } from "react-router";
import { getScanUrlForAddress } from "../../../../utils/scanUri";

interface props {
    wallet: IWallet
}

const Wallet: FC<props> = ({wallet}) => {
    const balance = bigintToNumber(wallet.balance, networkPrecision[wallet.network]) + " " + networkCurrencies[wallet.network] 
    const navigate = useNavigate()

    const goToExplorer = () => {
        const uri = getScanUrlForAddress(wallet.network, wallet.address)
        if (!uri) return
        window.open(uri, "_blank")
    }

    const goToWallet = () => {
        navigate(`/app/wallets/${wallet.id}`)
    }

    return (
        <div className="flex flex-col gap-3 p-6 rounded-2xl bg-content text-white items-center text-center text-lg font-medium cursor-pointer">
            <img src={networkLogoSrcs[wallet.network]} alt={networkNames[wallet.network]} className="w-12 h-12 rounded-full" />
            <span className="truncate w-full clickable-link" onClick={goToWallet}>{wallet.name}</span>
            <span className="truncate w-full clickable-link max-w-[300px]" onClick={goToExplorer}>{wallet.address}</span>
            <span className="text-emerald-500">{balance}</span>
        </div>
    )
}

export default Wallet