import { FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { authApi } from '../../../api/auth'

const AuthPage = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const emailValid = email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (!emailValid) return setError('Please enter a valid email address')
    if (!password) return setError('Please enter a password')
    setError(undefined)
    setSubmitting(true)
    authApi.signIn(email, password).catch((err) => {
      switch (err.code) {
        case 'auth/invalid-email':
          setError('Please enter a valid email address')
          break
        case 'auth/user-disabled':
          setError('This account has been disabled')
          break
        case 'auth/user-not-found':
          setError('This account does not exist')
          break
        case 'auth/wrong-password':
          setError('Incorrect password')
          break
        default:
          setError('An unknown error occurred')
      }
      setSubmitting(false)
    })

    return false
  }

  return (
    <div
     className="flex h-full w-full p-8 items-center justify-center fade-in bg-gradient"
     >
    <div className="flex flex-col p-8 gap-8 rounded-3xl w-[440px] max-w-full bg-content shadow-2xl backdrop-blur-[64px]">
      <h1 className="text-emerald-400 w-full text-center text-xl font-bold">Welcome Back</h1>
      <form className="flex flex-col w-full gap-8 items-center px-4" name="login" onSubmit={handleSubmit}>
        <input
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          className="grow p-3 w-full rounded-xl outline-none bg-content autofill:bg-content text-white text-base"
        />
        <input
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          className="grow p-3 w-full rounded-xl outline-none bg-content text-white text-base"
        />
        <button className="bg-slate-950 w-max px-8 hover:bg-slate-900 transition-all text-white p-3 rounded-xl" disabled={submitting} children={submitting ? '...' : 'Sign In'} onSubmit={handleSubmit} />
      </form>
      {error && <p className="text-rose-500 text-center">{error}</p>}
      <div className="">
        <p className="text-emerald-400 text-center">
          Dont have an account?{' '}
          <Link className="text-white hover:underline underline-offset-2" to="/auth/register">
            Sign Up
          </Link>
        </p>
        <p className="text-emerald-400 text-center pt-1">
          Forgot password?{' '}
          <Link className="text-white hover:underline underline-offset-2" to="/auth/reset-password">
            Reset
          </Link>
        </p>
      </div>
    </div>
    </div>
  )
}

export default AuthPage
