import { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'

interface Question {
  heading: string[]
  bold: number[]
  body: {
    heading: string
    body: string
  }[]
}

interface props {
  question: Question | null
  onDone: () => void
}

const Writer: FC<PropsWithChildren<props>> = ({ question, onDone, children }) => {
  const questionRef = useRef<Question | null>(null)

  const baseKey = question?.heading.join('') || 'null'

  const [done, setDone] = useState(false)

  const timeouts = useRef<NodeJS.Timeout[]>([])

  const [headingElements, setHeadingElements] = useState<HTMLSpanElement[]>([])

  const [bodyElements, setBodyElements] = useState<{ heading: string[]; body: string[] }[]>([])

  const cursorRef = useRef<HTMLSpanElement | null>(null)
  const autoScroll = useRef(true)

  const writeCharToHeading = (char: string, bold: boolean) => {
    const span = document.createElement('span')
    span.innerText = char
    span.classList.add('text-emerald-500')
    if (bold) span.classList.add('font-extrabold')
    setHeadingElements((old) => [...old, span])
  }

  const writeHeading = useCallback((data: { heading: string[]; bold: number[] }, timeoutOffset: number) => {
    if (questionRef.current === null) return timeoutOffset
    const { heading, bold } = data
    let charsToWrite = [] as { char: string; bold: boolean }[]
    charsToWrite.push({ char: '.', bold: false })
    charsToWrite.push({ char: ' ', bold: false })
    charsToWrite.push({ char: '/', bold: false })
    charsToWrite.push({ char: ' ', bold: false })
    heading.forEach((char, index) => {
      const isLast = index === heading.length - 1
      for (let i = 0; i < char.length; i++) {
        charsToWrite.push({ char: char[i], bold: bold.includes(index) })
      }
      if (!isLast) charsToWrite.push({ char: '_', bold: false })
    })

    charsToWrite.forEach(({ char, bold }, index) => {
      const isSpecial = char === '.' || char === '/' || char === '_'
      const timeout = isSpecial ? 50 : 100
      timeoutOffset += timeout
      timeouts.current.push(setTimeout(() => writeCharToHeading(char, bold), timeoutOffset))
    })

    return timeoutOffset
  }, [])

  const writeTokenToBodyHeading = (token: string, index: number) => {
    setBodyElements((old) => {
      if (old[index] === undefined) {
        old[index] = { heading: [], body: [] }
      }
      return old.map((element, i) => {
        if (i === index) {
          return {
            ...element,
            heading: [...element.heading, token],
          }
        }
        return element
      })
    })
  }

  const writeTokenToBodyBody = (token: string, index: number) => {
    setBodyElements((old) => {
      return old.map((element, i) => {
        if (i === index) {
          return {
            ...element,
            body: [...element.body, token],
          }
        }
        return element
      })
    })
  }

  const writeBodyChild = useCallback((child: { body: string; heading: string }, childIndex: number, timeoutOffset: number) => {
    const headingTokens = child.heading.split(' ')
    let currentTimeout = timeoutOffset
    headingTokens.forEach((token, index) => {
      const isLast = index === headingTokens.length - 1
      const timeout = isLast ? 25 : 50
      currentTimeout += timeout
      timeouts.current.push(setTimeout(() => writeTokenToBodyHeading(token, childIndex), currentTimeout))
    })
    const bodyTokens = child.body.split(' ')
    bodyTokens.forEach((token, index) => {
      const isLast = index === bodyTokens.length - 1
      const timeout = isLast ? 25 : 50
      currentTimeout += timeout
      timeouts.current.push(setTimeout(() => writeTokenToBodyBody(token, childIndex), currentTimeout))
    })

    return currentTimeout
  }, [])

  useEffect(() => {
    setHeadingElements([])
    setBodyElements([])
    setDone(false)
    if (question === null) return
    if (questionRef.current !== null && questionRef.current.heading.join('') === question.heading.join('')) return
    autoScroll.current = true

    questionRef.current = { ...question }
    const initialTimeout = 0
    const { heading, bold } = question
    let currentOffset = writeHeading({ heading, bold }, initialTimeout)
    for (let i = 0; i < question.body.length; i++) {
      currentOffset = writeBodyChild(question.body[i], i, currentOffset)
    }
    timeouts.current.push(setTimeout(() => setDone(true), currentOffset + 500))

    return () => {
      timeouts.current.forEach((timeout) => clearTimeout(timeout))
      timeouts.current = []
    }
  }, [question, writeHeading, writeBodyChild])

  useEffect(() => {
    if (!done) return
    onDone()
    questionRef.current = null
  }, [done, onDone])

  useEffect(() => {
    const onScroll = (e: Event) => {
        autoScroll.current = false
    }
    window.addEventListener("touchmove", onScroll)
    window.addEventListener("wheel", onScroll)

    return () => {
      window.removeEventListener("touchmove", onScroll)
      window.removeEventListener("wheel", onScroll)
    }
  }, [])

  useEffect(() => {
    if (cursorRef.current === null) return
    if (!autoScroll.current) return
    cursorRef.current.classList.add('scroll-my-10')
    // @ts-ignore
    cursorRef.current.scrollIntoView({ behavior: 'instant', block: 'end' })
  }, [headingElements, bodyElements, done])

  if (!question)
    return (
      <span className="w-full text-left text-2xl text-emerald-500 font-slim mt-4">
        &gt;&emsp;<span className="blink">_</span>
      </span>
    )

  return (
    <div className="flex flex-col bg-slate-900 gap-4 mt-4">
      <span className="w-full text-left text-2xl text-emerald-500 font-slim mb-4">
        &gt;&emsp;
        {headingElements.map((element, index) => {
          return (
            <span key={baseKey + index} className={element.className}>
              {element.innerText}
            </span>
          )
        })}
        {!bodyElements.length && (
          <span ref={cursorRef} className="blink">
            |
          </span>
        )}
      </span>
      <div className="flex flex-col">
        {bodyElements.map((element, index) => {
          const baseKey = element.heading.join('') + element.body.join('')
          const heading = element.heading.join(' ')
          const body = element.body.join(' ')
          return (
            <span key={baseKey + index} className="text-emerald-400 text-2xl font-bold px-6 pb-6">
              {!!heading && (
                <>
                  {heading}
                  {!body.length && <span ref={cursorRef}>|</span>}
                  <br />
                </>
              )}
              {!!body && (
                <span className="text-base text-emerald-500 text-justify w-full">
                  {body}
                  {!bodyElements[index + 1]?.heading && !done && <span ref={cursorRef}>|</span>}
                </span>
              )}
            </span>
          )
        })}
      </div>
      {done && (
      <>
        {children}
        <span ref={cursorRef} />
      </>
      )}
    </div>
  )
}

export default Writer
