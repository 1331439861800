import { default as baseAxios } from 'axios'
import { getToken, isTokenValid, setToken } from '../utils/tokens'
import toasts from '../components/toasts'

const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:3000/api" : window.location.origin + '/api'

export const axios = baseAxios.create({
  baseURL,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
  },
})

const authorizedAxiosInstance = baseAxios.create({
  baseURL,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
  },
})

authorizedAxiosInstance.interceptors.request.use(async (config) => {
  const token = getToken("client")
  if (isTokenValid(token)) {
    config.headers.Authorization = `Bearer ${token}`
    return config
  } else {
    const refresh = getToken("refresh")
    if (isTokenValid(refresh)) {
      const response = await axios.post('/auth/refresh', { refreshToken: refresh }).then(
        (res) =>
          res.data as {
            token: string
            refreshToken: string
          }
      )
      setToken("client", response.token)
      setToken("refresh", response.refreshToken)
      config.headers.Authorization = `Bearer ${response.token}`

      return config
    } else {
      throw new Error('No valid token found')
    }
  }
})

authorizedAxiosInstance.interceptors.response.use((response) => response, async (error) => {
  if (error.response.data) {
    toasts.error(error.response.data?.error)
    return Promise.reject(error.response.data)
  }
  throw error
})

export const authorizedAxios = authorizedAxiosInstance
