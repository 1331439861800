import { FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { authApi } from '../../../api/auth'

const RegisterPage = () => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>()
  const [phone, setPhone] = useState<string>()
  const [password, setPassword] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const passwordValid = password.length >= 8 && password.match(/[A-Z]/g) && password.match(/[0-9]/g)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    setSubmitting(true)
    if (!email) return
    if (!passwordValid) return setError('Password must be at least 8 characters long and contain at least one number and one capital letter')
    if (!name) return setError('Please enter your name')
    if (!phone) return setError('Please enter your phone number')
    setError(undefined)
    authApi
      .signUp(email, password, name, phone)
      .catch((e) => {
        switch (e.code) {
          case 'auth/invalid-email':
            setError('Please enter a valid email address')
            break
          case 'auth/user-disabled':
            setError('This account has been disabled')
            break
          case 'auth/user-not-found':
            setError('This account does not exist')
            break
          case 'auth/wrong-password':
            setError('Incorrect password')
            break
          default:
            setError('An unknown error occurred')
        }
        setSubmitting(false)
      })

    return false
  }

  return (
    <div
     className="flex h-full w-full p-8 items-center justify-center fade-in bg-gradient overflow-y-auto"
     >
    <div className="flex flex-col p-8 gap-8 rounded-3xl w-[440px] max-w-full bg-content shadow-2xl backdrop-blur-[64px]">
        <h1 className="text-emerald-400 w-full text-center text-xl font-bold">Create an Account</h1>
        <form className="flex flex-col w-full gap-8 items-center px-4" name="signup" onSubmit={handleSubmit}>
          <input placeholder="Name" value={name} onChange={(e) => setName(e.currentTarget.value)}
          className="grow p-3 w-full rounded-xl outline-none bg-content autofill:bg-content text-white text-base" />
          <input placeholder="Email" value={email} onChange={(e) => setEmail(e.currentTarget.value)} 
          className="grow p-3 w-full rounded-xl outline-none bg-content autofill:bg-content text-white text-base" />
          <input
            placeholder="Phone Number"
            value={phone}
            type="tel"
            onChange={(e) => setPhone(e.currentTarget.value)}
          className="grow p-3 w-full rounded-xl outline-none bg-content autofill:bg-content text-white text-base"
          />
          <input
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          className="grow p-3 w-full rounded-xl outline-none bg-content autofill:bg-content text-white text-base"
          />
          <p className="text-emerald-400 text-center text-xs max-w-[400px]">
            All fields are required. By clicking 'Register', you are indicating that you have read and agree to the{' '}
            <Link className="text-white hover:underline underline-offset-2" to="/terms-of-service">
              Terms of Service
            </Link>{' '}
            and 
            <Link className="text-white hover:underline underline-offset-2" to="/privacy-policy">
              Privacy Policy
            </Link>
            .
          </p>
          <button className="bg-slate-950 w-max px-8 hover:bg-slate-900 transition-all text-white p-3 rounded-xl" children={submitting ? '...' : 'Sign Up'} disabled={!name || !passwordValid || submitting} />
        </form>
        {error && <p className="text-rose-500 text-center">{error}</p>}
        <div>
          <p className="text-emerald-400 text-center pt-1">
            Already have an account?{' '}
            <Link className="text-white hover:underline underline-offset-2" to="/auth">
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default RegisterPage
