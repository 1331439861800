import { Api } from "..";
import { Network } from "../../types/network";
import { IWallet } from "./types";

class WalletsApi extends Api {
    async getWallets() {
        return this.api.get('/wallets').then(res => res.data.wallets as IWallet[])
    }

    async createWallet(network: Network, name: string, privateKey?: string) {
        return await this.api.post('/wallets', {
            network,
            privateKey,
            name
        }).then(res => res.data.wallet as IWallet);
    }
}

export const walletsApi = new WalletsApi();