import { Navigate, Route, Routes } from 'react-router';
import useAuthContext from './contexts/Auth/useAuthContext';
import AuthorizedRouter from './router/Authorized';
import UnauthorizedRouter from './router/Unauthorized';
import PublicRouter from './router/Public';
import { useEffect } from 'react';
import isMobile from './utils/isMobile';

function Root() {
  const user = useAuthContext()

  /*window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        chainId: 1,
        options: {
          address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",  // ERC20 token address
          symbol: `DAI`,
          decimals: 18,
          image: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png',
        },
      },
    })*/

    

  useEffect(() => {
    if (!isMobile()) return
    const styleSheets = Array.from(document.styleSheets)
    styleSheets.forEach((styleSheet) => {
      if (styleSheet.ownerNode?.nodeName === "STYLE") {
        const rules = Array.from(styleSheet.cssRules)
        rules.forEach((rule) => {
          if (rule instanceof CSSStyleRule) {
            if (rule.selectorText.includes(":hover")) {
              rule.selectorText = rule.selectorText.replace(":hover", ":active")
            }
          }
        })
      }
    })
  }, [])
  
  if (user === undefined) return (<div>Loading...</div>)

  return (
      <Routes>
        <Route path="app/*" element={user !== null ? <AuthorizedRouter /> : <Navigate to="/auth" replace />} />
        <Route path="auth/*" element={user === null ? <UnauthorizedRouter /> : <Navigate to="/app" replace />} />
        <Route path="*" element={<PublicRouter />} />
      </Routes>
      
  );
}

export default Root;
