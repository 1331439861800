import { useState } from 'react'
import Modal from '../../../../components/Modal'
import { AwaitableModal } from '../../../../hooks/useAwaitableModal'
import Icons from '../../../../assets/icons'
import { tokensApi } from '../../../../api/tokens'
import { IToken } from '../../../../api/tokens/types'
import { Network } from '../../../../types/network'

const AddNewTokenModal: AwaitableModal<Omit<IToken, 'networks'> & { networks: Network[] }, {}> = ({ resolve, onCancel, open }) => {
  const [name, setName] = useState('')
  const [symbol, setSymbol] = useState('')
  const [image, setImage] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return
    const reader = new FileReader()
    reader.onload = (e) => {
      const result = e.target?.result
      if (typeof result !== 'string') return
      setImage(result)
    }
    reader.readAsDataURL(file)
  }

  const handleSubmit = () => {
    if (submitting) return
    if (!name) return
    if (!symbol) return
    if (!image) return
    setSubmitting(true)
    tokensApi
      .createToken(name, symbol, image)
      .then((created) => resolve(created))
      .catch(() => setSubmitting(false))
  }

  return (
    <Modal open={open} close={onCancel}>
      <div className="flex flex-col p-6 gap-6 rounded-3xl items-center w-[440px] max-w-full bg-content shadow-2xl backdrop-blur-[64px]">
        <h2 className="font-bold text-xl w-full text-center text-emerald-500">Add a new token</h2>
        <div className="flex items-center w-full gap-4">
          <input type="text" placeholder="Name" className="input w-full grow" value={name} onChange={(e) => setName(e.target.value)} />
          <input type="text" placeholder="Symbol" className="input w-[90px]" value={symbol} onChange={(e) => setSymbol(e.target.value)} />
          <label htmlFor="token-image" className="w-10 h-10 rounded-full shrink-0">
            {image ? (
              <img src={image} alt="Token Image" className="w-10 h-10 rounded-full shrink-0 group-hover:p-1 transition-all" />
            ) : (
              <Icons.Picture className="w-10 h-10 group-hover:p-1 transition-all bg-white-10 rounded-full text-white" />
            )}
          </label>
        </div>
        <input id="token-image" className="hidden" accept=".png" placeholder="Token Image" type="file" onInput={handleImageChange} />
        <button
          className="btn-primary"
          onClick={handleSubmit}
        >
          Add
        </button>
      </div>
    </Modal>
  )
}

export default AddNewTokenModal
