import { AxiosInstance } from "axios"
import {axios, authorizedAxios} from "./axios";

export class Api {
    api: AxiosInstance;
    baseApi: AxiosInstance;
    constructor() {
        this.api = authorizedAxios;
        this.baseApi = axios;
    }
}

export const api = new Api();