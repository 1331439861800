import { FC } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router";
import LandingPage from "../pages/public/Landing";
import useAuthContext from "../contexts/Auth/useAuthContext";
import IntegrateLayout from "../layouts/IntegrateLayout";
import IntegrationOverviewPage from "../pages/public/Integrate/Overview";
import IntegrationBridgingPage from "../pages/public/Integrate/Bridging";
import IntegrationErrorsPage from "../pages/public/Integrate/Errors";
import IntegrationContactPage from "../pages/public/Integrate/Contact";
import PricingPage from "../pages/public/Pricing/IntegrateLayout";


const PublicRouter: FC = () => {
    const user = useAuthContext()

    return (
        <Routes>
            <Route path="*" element={<Outlet />}>
                <Route index element={<LandingPage />} />
                <Route path="pricing" element={<PricingPage />} />
                <Route path="integrate" element={<IntegrateLayout />}>
                    <Route index />
                    <Route path="overview" element={<IntegrationOverviewPage />} />
                    <Route path="bridge-token" element={<IntegrationBridgingPage />} />
                    <Route path="errors" element={<IntegrationErrorsPage />} />
                    <Route path="support" element={<IntegrationContactPage />} />
                </Route>
                <Route path="*" element={<Navigate to={user === null ? '/auth' : '/app'} replace />} />
            </Route>
        </Routes>
    )
}

export default PublicRouter