import { FC } from "react"
import {  useNavigate } from "react-router"
import { Logo } from "../../../assets/logo"

const locationToTitle = (path: string) => {
  switch (path) {
    case "":
      return ""
    case "/overview":
      return "basics_overview"
    case "/bridge-token":
      return "bridging_a_token"
    case "/errors":
      return "handling_errors"
    case "/support":
      return "contacting_support"
    default:
      return ""
  }
}

const PricingPage: FC = () => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col min-h-full w-full bg-slate-900">
      <div className="flex bg-slate-950 z-[100] w-full items-center px-4 py-3">
        <div className="flex items-center gap-8 text-lg">
          <Logo height={36} className="cursor-pointer" onClick={() => navigate('/')} />
          <span className="text-emerald-500">pricing</span>
        </div>
      </div>
      <div className="flex flex-col gap-4 p-10">
        <div className="grow">
          Pricing
          </div>
      </div>
      <div className="text-slate-700 text-center py-4 bg-slate-950">
        {/*Designed by <span className="font-bold">Aneta Potaś</span> • Developed by <span className="font-bold">Martin Repka</span>
        <br />*/}
        <span className="text-sm">by</span>{' '}
        <a className="font-bold hover:underline cursor-pointer leading-[200%]" href="https://tomorrow.biz" target="_blank" rel="noreferrer">
          tomorrow.biz
        </a>
        <br />© 2023
      </div>
    </div>
  )
}

export default PricingPage