import { FC, useEffect, useState } from 'react'

import { tokensApi } from '../../../api/tokens'
import { IToken } from '../../../api/tokens/types'
import useAwaitableModal from '../../../hooks/useAwaitableModal'
import AddNewTokenModal from './modals/AddNewToken'
import DeployTokenModal from './pages/Token/modals/DeployToken'
import useTitle from '../../../contexts/Title/useTitle'
import Token from './components/Token'
import { Network } from '../../../types/network'
import Icons from '../../../assets/icons'

const TokensPage: FC = () => {
  const [tokens, setTokens] = useState<(Omit<IToken, 'networks'> & { networks: Network[]; supply: string })[]>()
  const [addNewToken, AddNewTokenComponent] = useAwaitableModal(AddNewTokenModal, {})
  const [deployToken, DeployTokenComponent] = useAwaitableModal(DeployTokenModal, {} as any)

  useTitle("Your Tokens")

  useEffect(() => {
    tokensApi.getTokens().then(setTokens)
  }, [])
  
  const onAdd = () => addNewToken().then((created) => created && setTokens((old) => old && [...old, { ...created, supply: '0' }]))

  if (!tokens) return (
    <div className='flex items-center justify-center w-full h-full'>
      <Icons.NewTokenLoading className='animate-spin w-10 h-10 text-white' />
    </div>
  )

  return (
    <div className="grid grid-cols-3 gap-8 p-8 transition-all h-max [&>*]:fade-in">
      <AddNewTokenComponent />
          {tokens?.map((token) => (
            <Token key={token.id} token={token as any} />
          ))}
        <div className="flex flex-col gap-2 p-4 rounded-2xl bg-content items-center justify-center cursor-pointer group min-h-[200px] group hover:bg-white-5 transition-all" onClick={onAdd}>
            <span className="w-10 h-10 bg-content items-center justify-center rounded-full transition-transform text-white flex text-xl pb-px group-hover:scale-[150%]">+</span>
        </div>
    </div>
  )
}

export default TokensPage
