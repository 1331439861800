import { EthereumLogoSrc, PolygonLogoSrc } from "../assets/images/networks";

export const networkNames = {
    eth: "Ethereum",
    pol: "Polygon"
} as const

export const networkCurrencies = {
    eth: "ETH",
    pol: "MATIC"
} as const

export const networkPrecision = {
    eth: 18,
    pol: 18
} as const

export const networkLogoSrcs = {
    eth: EthereumLogoSrc,
    pol: PolygonLogoSrc,
} as const

export const networkChainIds = process.env.NODE_ENV === "production" ? {
    pol: 137,
    eth: 1
} as const : {
    pol: 80001,
    eth: 11155111,
} as const

export const availableNetworks = Object.keys(networkNames) as (keyof typeof networkNames)[];

export const metamaskNetworkConfig = process.env.NODE_ENV === "production" ? {
    pol: {
        chainId: '0x' + networkChainIds.pol.toString(16),
        chainName: "Polygon Mainnet",
        nativeCurrency: {
            symbol: networkCurrencies.pol.toString(),
            decimals: networkPrecision.pol.toString(),
        },
        rpcUrls: ["https://polygon-mainnet.infura.io"],
    },
    eth: {
        chainId: '0x' + networkChainIds.eth.toString(16),
        chainName: "Ethereum Mainnet",
        nativeCurrency: {
            symbol: networkCurrencies.eth.toString(),
            decimals: networkPrecision.eth.toString(),
        },
        rpcUrls: ["https://mainnet.infura.io"],
    }
} : {
    pol: {
        chainId: '0x' + networkChainIds.pol.toString(16),
        chainName: "Polygon Mumbai",
        nativeCurrency: {
            symbol: networkCurrencies.pol.toString(),
            decimals: networkPrecision.pol.toString(),
        },
        rpcUrls: ["	https://polygon-mumbai.infura.io/v3"],
    },
    eth: {
        chainId: '0x' + networkChainIds.eth.toString(16),
        chainName: "Ethereum Sepolia",
        nativeCurrency: {
            symbol: networkCurrencies.eth.toString(),
            decimals: networkPrecision.eth.toString(),
        },
        rpcUrls: ["https://sepolia.infura.io"],
    }
}