import { FC } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs'

const mintCode = `import { createHmac } from 'crypto'
import axios from 'axios'

...

const url = '<mint endpoint from token page under dev settings>'
const network = 'eth' // or 'pol' for polygon
const tokenId = '<token id from token page>'
const tokenAmount = amount * (10 ** digits) // amount of token to mint

const apiKey =
    '<api key from token page under dev settings>'

const toSign = \`\${tokenId}:\${address}:\${tokenAmount}:\${userId}:\${network}\`
const signature = createHmac('sha256', apiKey)
    .update(toSign)
    .digest('hex')

const body = {
    token: tokenId,
    address,
    uid: userId,
    amount: tokenAmount,
    signature,
    network,
}

try {
    const txId = await axios.post(url, body).then((res) => res.data)
    // handle success
} catch (err: any) {
    // handle error
}`

const burnCode = `import { createHmac } from 'crypto'

...

const { id, uid: userId, value: tokenValue, token, network: network, signature } = req.query
const digits = 6

const value = parseInt(tokenValue) / (10 ** digits) // amount of token burned (in token units)

const signingKey =
    '<signing key from token page under dev settings>'

const toSign = \`\${id}:\${userId}:\${tokenValue}:\${token}:\${network}\`
const calculatedSignature = crypto
  .createHmac('sha256', signingKey)
  .update(toSign)
  .digest('hex')

if (signature !== calculatedSignature) {
  return res.status(400).json({ error: 'Invalid signature' })
}

// handle burn

return res.sendStatus(200)`

const IntegrationBridgingPage: FC = () => {
  return (
    <div className="px-10 pb-10 -mt-4 text-white text-lg flex flex-col">
      <span className="text-2xl font-semibold mt-4">In-App → Crypto</span>
      <span className="text-lg font-normal leading-[200%] pl-3 mt-2 whitespace-pre-lines pb-4">
        Token is issued by making a <b>POST</b> request to the <b>mint endpoint</b>.<br />
        <br />
        You can find the <b>mint endpoint</b> in the token page under <b>dev settings &lt;/&gt;</b>.<br />
        <br />
        Call the endpoint with the following data:
        <ul className="list-disc px-10">
          <li>
            <b>amount</b> (amount of token to mint)
          </li>
          <li>
            <b>uid</b> (your internal user id)
          </li>
          <li>
            <b>address</b> (the on-chain address of the user's wallet)
          </li>
          <li>
            <b>signature</b> (signature of the data)
          </li>
        </ul>
        <br />
        <br />
        To get the signature, format the data into a string as follows:
        <br /> {`{tokenId}:{address}:{onChainTokenAmount}:{userId}:{network}`}
        <br /> Then create a <b>hex-digested sha256 hmac</b> using the <b>api key</b> found in the token page under <b>dev settings &lt;/&gt;</b>.<br />
        <br />
        Successful response will be a <b>txId</b> - the on-chain id of the transaction.
        <br />
        <br />
        <span className="text-xl font-semibold mt-4">
          Example <b>node.js</b> code
        </span>
        <SyntaxHighlighter customStyle={{ backgroundColor: '#020617', marginBlock: 16 }} language="javascript" showLineNumbers style={vs2015}>
          {mintCode}
        </SyntaxHighlighter>
      </span>
      <span className="text-2xl font-semibold mt-2">Crypto → In-App</span>
      <span className="text-lg font-normal leading-[200%] pl-3 mt-2 whitespace-pre-lines pb-4">
        To handle the reverse situation, create a <b>burn webhook</b> endpoint on your server and put it in the token page under <b>dev settings &lt;/&gt;</b>.<br />
        <br />
        Crydge will make a <b>GET</b> request to this endpoint with the following query data:
        <ul className="list-disc px-10">
          <li>
            <b>id</b> (the txId of the transaction)
          </li>
          <li>
            <b>uid</b> (your internal user id)
          </li>
          <li>
            <b>value</b> (the on-chain amount of token burned)
          </li>
          <li>
            <b>token</b> (the id of the token)
          </li>
          <li>
            <b>network</b> (the network the token was burned on)
          </li>
          <li>
            <b>signature</b> (signature of the data)
          </li>
        </ul>
        <br />
        <br />
        To verify the signature, format the data into a string as follows:
        <br /> {`{id}:{userId}:{value}:{token}:{network}`}
        <br /> Then create a <b>hex-digested sha256 hmac</b> using the <b>signing key</b> found in the token page under <b>dev settings &lt;/&gt;</b> and compare it with the signature.<br />
        <br />
        Send back status code <b>200</b>.<br />
        <br />
        <span className="text-xl font-semibold mt-4">
          Example <b>node.js</b> code
        </span>
        <SyntaxHighlighter customStyle={{ backgroundColor: '#020617', marginBlock: 16 }} language="javascript" showLineNumbers style={vs2015}>
          {burnCode}
        </SyntaxHighlighter>
      </span>
    </div>
  )
}

export default IntegrationBridgingPage
