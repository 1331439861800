import { FC } from "react";
import { networkLogoSrcs } from "../../../../constants/networks";
import { useNavigate } from "react-router";
import { INetworkToken, IToken } from "../../../../api/tokens/types";
import { bigintToNumber } from "../../../../utils/balance";

interface props {
    token: Omit<IToken, 'networks'> & { networks: INetworkToken[]; supply: string }
}

const Token: FC<props> = ({token}) => {
    const navigate = useNavigate()
    const circulation = bigintToNumber(token.supply, 6) + " " + token.symbol

    const goToToken = () => {
        navigate(`/app/tokens/${token.id}`)
    }

    return (
        <div className="flex flex-col gap-3 p-6 rounded-2xl bg-content text-white items-center text-center text-lg font-medium cursor-pointer">
            <img src={token.image} alt={token.name} className="w-12 h-12 rounded-full" />
            <span className="truncate w-full clickable-link" onClick={goToToken}>{token.name}</span>
            <div className="flex gap-4 w-full justify-center overflow-x-auto">
                {token.networks?.length ? token.networks.map((network) => (
                    <img src={networkLogoSrcs[network.network]} alt={network.network} className="w-8 h-8 rounded-full" />
                )): (
                    <div className="rounded-full w-8 h-8 bg-white-5" />
                )}
            </div>
            <span className="text-emerald-500">Circulation: {circulation}</span>
        </div>
    )
}

export default Token