import { useEffect, useState } from 'react'
import { tokensApi } from '../../../../../../api/tokens'
import { IToken } from '../../../../../../api/tokens/types'
import Modal from '../../../../../../components/Modal'
import useAwaitableModal, { AwaitableModal } from '../../../../../../hooks/useAwaitableModal'
import { Network } from '../../../../../../types/network'
import AreYouSureModal from '../../../../../../components/modals/AreYouSure'
import { axios } from '../../../../../../api/axios'
import toasts from '../../../../../../components/toasts'
import { copyText } from '../../../../../../utils/copyText'

const DeveloperSettingsModal: AwaitableModal<Omit<IToken, 'networks'> & { networks: Network[] }, { token: IToken }> = ({ resolve, onCancel, open, initialData }) => {
  const [areYouSure, AreYouSureComponent] = useAwaitableModal(AreYouSureModal, {})
  const [submitting, setSubmitting] = useState(false)
  const [notifyUrl, setNotifyUrl] = useState<string>()
  const [token, setToken] = useState<IToken | undefined>(initialData.token)

  useEffect(() => {
    setToken(initialData.token)
    setNotifyUrl(initialData.token?.notifyUrl)
  }, [initialData.token])

  const hasChanges = notifyUrl !== token?.notifyUrl

  const formatSecret = (secret?: string) => {
    if (!secret) return '---'
    const stars = '•'.repeat(32)
    return secret.slice(0, 4) + stars + secret.slice(-4)
  }

  const issueUrl = axios.defaults.baseURL + '/minter/' + token?.id + ''

  const onReset = () => {
    if (submitting) return
    setSubmitting(true)
    tokensApi
      .resetKeys(token?.id || '')
      .then(updated => {
        toasts.success('Keys reset')
        setToken(old => old && ({...old, ...updated}))
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const onSave = () => {
    if (submitting) return
    if (!notifyUrl) return
    setSubmitting(true)
    tokensApi
      .setNotifyUrl(token?.id || '', notifyUrl)
      .then(updated => {
        resolve({...token, ...updated})
        toasts.success('Settings saved')
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <Modal open={open} close={onCancel}>
      <AreYouSureComponent />
      <div className="flex flex-col p-6 gap-6 rounded-3xl items-center w-[440px] max-w-full bg-content shadow-2xl backdrop-blur-[64px]">
        <h2 className="font-bold text-lg w-full text-center text-emerald-400">Settings</h2>
        <div className="flex w-full flex-col gap-6">
          <div className="flex flex-col justify-between items-center py-1 gap-4">
            <h2 className="font-bold text-lg w-full text-left text-emerald-400 px-4">Endpoint URLs</h2>
            <div className="bg-white-10 rounded-xl flex gap-4 w-full items-center !text-emerald-400">
              <span className="px-4">Mint</span>
              <input type="text" readOnly value={issueUrl} onClick={() => copyText(issueUrl)} className="!bg-transparent input grow !text-right truncate clickable-link text-emerald-400" />
            </div>
            <div className="bg-white-10 rounded-xl flex gap-4 w-full items-center !text-emerald-400">
              <span className="px-4">Burn</span>
              <input
                type="text"
                value={notifyUrl}
                placeholder='Your webhook url'
                onChange={(e) => setNotifyUrl(e.currentTarget.value)}
                className="!bg-transparent input grow !text-right truncate"
              />
            </div>
          </div>
          <div className="flex flex-col justify-between items-center py-1 gap-4">
            <div className="flex justify-between items-center w-full px-4">
            <h2 className="font-bold text-lg w-full text-left text-emerald-400">Secrets</h2>
            <span className="clickable-link text-white" onClick={() => areYouSure({ text: 'Existing keys will be invalidated!' }).then(() => onReset())}>
              reset
            </span>
            </div>
            <div className="bg-white-10 rounded-xl flex gap-4 w-full items-center !text-emerald-400">
              <span className="pl-4">Api key</span>
              <input type="text" readOnly value={formatSecret(token?.apiKey || '')} onClick={() => copyText(token?.apiKey || "")} className="!bg-transparent input grow !text-right clickable-link text-emerald-400" />
            </div>
            <div className="bg-white-10 rounded-xl flex gap-4 w-full items-center !text-emerald-400">
              <span className="pl-4">Signing key</span>
              <input type="text" readOnly value={formatSecret(token?.signingKey || '')} onClick={() => copyText(token?.apiKey || "")} className="!bg-transparent input grow !text-right clickable-link text-emerald-400" />
            </div>
          </div>
        </div>
          <button className={["btn-primary", (!hasChanges || submitting) && "hover:!bg-white brightness-80"].asClass} onClick={onSave}>
            Save
          </button>
      </div>
    </Modal>
  )
}

export default DeveloperSettingsModal
