import { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import Writer from './components/Writer'
import { Logo } from '../../../assets/logo'

const questions = [
  {
    heading: ['whats', 'the', 'offer'],
    bold: [2] as number[],
    body: [
      {
        heading: '>> Seamless integration',
        body: 'Integrate Crydge seamlessly with your existing in-app currency system. Our APIs are the bridge that effortlessly connects your virtual currency with real crypto tokens, offering users a new and exciting way to engage.',
      },
      {
        heading: '>> Expand User Opportunities',
        body: 'Empower your users to go beyond in-app transactions. With Crydge, they can convert their earned virtual currency into popular cryptocurrencies, providing a bridge to a world of possibilities and rewards.',
      },
      {
        heading: '>> Enhance Loyalty Programs',
        body: 'Take your loyalty programs to the next level. Reward your users not only with in-app perks but also with the freedom to choose from a variety of real-world rewards, investment opportunities, and more.',
      },
    ],
  },
  {
    heading: ['what', 'it', 'includes'],
    bold: [2] as number[],
    body: [
      {
        heading: '>> Customizable Integration',
        body: 'Tailor the integration to align with your brand and user experience seamlessly.',
      },
      {
        heading: '>> 2-Step Currency Deployment',
        body: 'Simplify the complex process of currency creation and deployment with our user-friendly approach, supporting popular networks Ethereum and Polygon with more to come',
      },
      {
        heading: '>> Straightforward FE/BE Implementation',
        body: 'Easily integrate our solution into your front-end and back-end systems.',
      },
      {
        heading: '>> Extensive Dashboard',
        body: 'Gain powerful insights and control over user trends with our feature-rich dashboard.',
      },
      {
        heading: '>> Metamask Integration',
        body: "Enhance user experience with a one-click 'Add to Metamask' Button",
      },
      {
        heading: '>> Extensive Dashboard',
        body: 'Gain powerful insights and control over user trends with our feature-rich dashboard.',
      },
      {
        heading: '>> Development Environment',
        body: 'Start Playing Around in the Platform without worries.',
      },
      {
        heading: '>> [Coming Soon] API Account Management',
        body: 'Streamline account management and offer B2B solutions with our upcoming API feature.',
      },
      {
        heading: '>> [Coming Soon] 2-Step Uniswap Launch',
        body: 'Stay tuned for an upcoming feature that will take your projects to new heights.',
      },
    ],
  },
  {
    heading: ['how', 'it', 'works'],
    bold: [2] as number[],
    body: [
      {
        heading: '>> Create a Token',
        body: 'Craft and deploy your own currency token effortlessly.',
      },
      {
        heading: '>> Server Notification Setup',
        body: 'Handle incoming currency balance updates with a simple endpoint configuration.',
      },
      {
        heading: '>> Open the Bridge',
        body: 'Seamlessly connect with your users and open the gateway to endless possibilities.',
      },
    ],
  },
  {
    heading: ['whos', 'it', 'for'],
    bold: [2] as number[],
    body: [
      {
        heading: '>> Gaming Companies',
        body: 'Tailored for game companies, our platform provides a competitive edge in the gaming industry, while gaining crucial insights with our dashboard, optimizing in-game economies and player engagement.',
      },
      {
        heading: '>> B2B Companies Extending Product Portfolios [COMING SOON]',
        body: 'Offer new dimensions for your clients with our seamless API integration into your current product portfolio.',
      },
      {
        heading: '>> Social Networks',
        body: 'Create virtual currencies and monetize user interactions, encourage content creation, and engagement while strengthening community ties, offering virtual currency incentives for active participation and contributions.',
      },
    ],
  },
] as const

const LandingPage: FC = () => {
  const navigate = useNavigate()
  const [heading, setHeading] = useState<number>()

  const question = heading !== undefined ? (questions[heading] as any) : null

  const selected = heading !== undefined

  const [writing, setWriting] = useState(false)

  const handleSelect = (index: number) => {
    setHeading(index)
    setWriting(true)
  }

  const onReset = () => {
    setHeading(undefined)
    setWriting(false)
  }

  return (
    <div className="bg-slate-950 h-full overflow-y-auto">
      <div className="flex flex-col min-h-full items-center justify-center relative text-center py-20 px-10 fade-in [&_*]:select-none">
        <div className="flex absolute bg-slate-900 z-[100] w-full top-0 items-center">
          <span
            className="w-max cursor-pointer text-left text-white text-lg before:content-['>'] before:invisible hover:before:visible py-4 hover:translate-x-2 text-white font-slim mx-4"
            onClick={() => navigate('/integrate')}
          >
            <span className="mx-4">integrate</span>
          </span>
          {/*<span
            className="w-max cursor-pointer text-left text-white text-lg before:content-['>'] before:invisible hover:before:visible py-4 hover:translate-x-2 text-white font-slim mx-4"
            onClick={() => navigate('/pricing')}
          >
            <span className="mx-4">pricing</span>
  </span>*/}
          <div className="grow flex items-center justify-end px-4">
            <div className="relative py-2 group cursor-pointer" onClick={() => navigate('/auth')}>
              <div className="bg-slate-950 rounded-xl absolute top-0 opacity-0 right-0 h-full w-0 group-hover:w-full group-hover:opacity-100 transition-all" />
              <div className="z-10 relative px-3 font-extrabold text-white transition-all">get_started</div>
            </div>
          </div>
        </div>
        <span className="text-2xl text-emerald-700 font-semibold">
          &gt; Welcome to{' '}
          <span className="font-extrabold text-emerald-500">
            <span className="text-white font-medium">C</span>rydge
          </span>{' '}
          &lt;
        </span>
        <span className="text-5xl text-emerald-400 font-medium mt-5 leading-[150%] relative">
          &lt; Bridging Your&nbsp;
          <span className="-top-2 relative">
            <span className="font-extrabold">Virtual</span> Currency
          </span>
          &nbsp;with the&nbsp;
          <span className="-top-2 relative">
            <span className="font-extrabold">Crypto</span> World
          </span>{' '}
          &gt;
        </span>
        <button className="relative mt-4 group cursor-pointer" onClick={() => navigate('/auth')}>
          <span className="text-xl text-emerald-700 font-medium z-10 flex gap-3">
            <span className="relative py-2 group-hover:translate-y-2">
              <span className="z-10 relative px-3 group-hover:text-white transition-all">Elevate Your Presence</span>
            </span>
            <span className="text-emerald-300 py-2 relative z-10 blink">|</span>
            <span className="relative py-2 group-hover:-translate-y-2">
              <span className="z-10 relative px-3 group-hover:text-white transition-all">Unlock the Crypto Realm</span>
            </span>
          </span>
        </button>
        <div className="flex flex-col gap-4 absolute bottom-4 items-center">
          <Logo className="w-20" />
          <span className="text-slate-700 font-slim">
            by{' '}
            <a className="font-bold hover:underline cursor-pointer text-lg" href="https://tomorrow.biz" target="_blank" rel="noreferrer">
              tomorrow.biz
            </a>
          </span>
        </div>
      </div>
      <div className="flex flex-col py-10 px-10 bg-slate-900 gap-4">
        <span className="w-full text-left text-2xl text-emerald-500 font-slim mb-2">
          &gt;&emsp;crydge <span className="text-emerald-400 font-extrabold">--help</span>
        </span>
        {questions.map((question, q) => {
          const tokens = question.heading.map((token, index) => {
            const bold = question.bold.includes(index)
            return (
              <span key={token} className={bold ? 'font-extrabold' : ''}>
                {token}
              </span>
            )
          })

          return (
            <span className="w-full cursor-pointer text-left text-white text-2xl hover:before:content-['>'] text-white font-slim py-1 mx-3.5 hover:pl-1" onClick={() => handleSelect(q)}>
              &emsp;
              {tokens.map((token, index) => {
                const isLast = index === tokens.length - 1
                return (
                  <span key={index}>
                    {token}
                    {!isLast && <span>_</span>}
                  </span>
                )
              })}
            </span>
          )
        })}
        <Writer question={question} onDone={() => setWriting(false)}>
          <span className="w-full text-left text-2xl text-emerald-500 font-slim mb-2 -mt-2">
            &gt;&emsp;crydge <span className="text-emerald-400 font-extrabold">--begin?</span>
          </span>
          <span className="w-full cursor-pointer text-left text-white text-2xl hover:before:content-['>'] text-white font-slim py-1 mx-3.5 hover:pl-1" onClick={() => navigate('/auth')}>
            &emsp; lets_get_<span className="font-extrabold">started</span>
          </span>
          <span className="w-full cursor-pointer text-left text-white text-2xl hover:before:content-['>'] text-white font-slim py-1 mx-3.5 hover:pl-1" onClick={onReset}>
            &emsp; ask_more_<span className="font-extrabold">questions</span>
          </span>
          <span className="w-full text-left text-2xl text-emerald-500 font-slim mt-4">
            &gt;&emsp;
            <span className="blink">_</span>
          </span>
        </Writer>
      </div>
      <div className="text-slate-700 text-center py-4">
        {/*Designed by <span className="font-bold">Aneta Potaś</span> • Developed by <span className="font-bold">Martin Repka</span>
        <br />*/}
        <span className="text-sm">by</span>{' '}
        <a className="font-bold hover:underline cursor-pointer leading-[200%]" href="https://tomorrow.biz" target="_blank" rel="noreferrer">
          tomorrow.biz
        </a>
        <br />© 2024
      </div>
    </div>
  )
}

export default LandingPage
