import { FC, useEffect, useRef, useState } from 'react'
import { Outlet, useNavigate } from 'react-router'
import Icons from '../assets/icons'
import useCurrentTitle from '../contexts/Title/useCurrentTitle'
import useCurrentSide from '../contexts/Title/useCurrentSide'

interface ButtonProps {
  icon: (typeof Icons)['Dashboard']
  route: string
  title: string
}

const Button: FC<ButtonProps> = ({ icon: Icon, route, title }) => {
  const [titleMeasure, setTitleMeasure] = useState(0)
  const navigate = useNavigate()
  const ref = useRef<HTMLSpanElement>(null)
  const [mouseIn, setMouseIn] = useState(false)

  useEffect(() => {
    const el = ref.current
    if (!el) return
    const measure = () => {
      setTitleMeasure(ref.current?.getBoundingClientRect().width || 0)
    }

    measure()

    const ro = new ResizeObserver(measure)

    ro.observe(el)

    return () => {
      ro.disconnect()
    }
  }, [ref])

  return (
    <div className="relative flex items-center cursor-pointer modal-overlay">
      <Icon onClick={() => navigate(route)} className="text-white w-10 h-10 peer cursor-pointer" onPointerOver={() => setMouseIn(true)} onPointerOut={() => setMouseIn(false)} />
      <div className="peer-hover:opacity-100 opacity-0 overflow-hidden transition-all" style={{ width: mouseIn ? titleMeasure + 10 : 0 }}>
        <span ref={ref} className="text-white pl-2">
          {title}
        </span>
      </div>
    </div>
  )
}

const MainLayout: FC = () => {
  const title = useCurrentTitle()
  const side = useCurrentSide()

  return (
    <div className="w-full h-full flex flex-col md:px-32 px-8 fade-in bg-gradient overflow-y-auto">
      <div className="flex items-center justify-start py-8 gap-8 px-8 modal-overlay">
        <div className="flex items-center gap-8 grow w-1/2">
          <Button icon={Icons.Dashboard} route="/app" title="Dashboard" />
          <Button icon={Icons.Tokens} route="/app/tokens" title="Tokens" />
          <Button icon={Icons.Wallets} route="/app/wallets" title="Wallets" />
        </div>
        <div className="min-w-max text-emerald-500 font-semibold text-xl hidden md:block">{title}</div>
        <div className="grow w-1/2 flex justify-end">
          {side ? side : <a className="text-white hover:underline cursor-pointer">logout</a>}
        </div>
      </div>
      <div className="md:hidden w-full text-center pb-8 modal-overlay">
        <div className="min-w-max text-emerald-500 font-semibold text-xl">{title}</div>
      </div>
      <div className="grow h-full rounded-t-3xl max-w-full bg-content shadow-2xl backdrop-blur-[64px] modal-overlay h-max">
        <Outlet />
      </div>
    </div>
  )
}

export default MainLayout
