import { ReactNode, useContext, useEffect, useRef } from "react"
import titleContext from "./titleContext"

const useSide = (component: ReactNode) => {
    const previous = useRef<ReactNode | undefined>()
    const {setSide} = useContext(titleContext);

    useEffect(() => {
        setSide(old => {
            previous.current = old
            return component
        })

        return () => {
            setSide(previous.current)
        }
    }, [component, setSide])
}

export default useSide