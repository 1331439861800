import { useEffect, useState } from 'react'
import Modal from '../../../../../../components/Modal'
import { AwaitableModal } from '../../../../../../hooks/useAwaitableModal'
import { INetworkToken, IToken } from '../../../../../../api/tokens/types'
import { walletsApi } from '../../../../../../api/wallets'
import { IWallet } from '../../../../../../api/wallets/types'
import { networkCurrencies, networkNames, networkPrecision } from '../../../../../../constants/networks'
import { contractsApi } from '../../../../../../api/contracts'
import { bigintToNumber } from '../../../../../../utils/balance'
import { Network } from '../../../../../../types/network'
import Icons from '../../../../../../assets/icons'

const DeployTokenModal: AwaitableModal<INetworkToken, { network: Network; token: IToken }> = ({ resolve, onCancel, open, initialData }) => {
  const [wallets, setWallets] = useState<IWallet[]>()
  const [wallet, setWallet] = useState('')
  const network = initialData.network
  const [estimatedGas, setEstimatedGas] = useState<string>()
  const [estimatedUSD, setEstimatedUSD] = useState<number>()
  const [submitting, setSubmitting] = useState(false)
  const token = initialData.token

  useEffect(() => {
    if (!wallet) return
    contractsApi.estimateFees(wallet, token.id).then((res) => {
      setEstimatedGas(res.native)
      setEstimatedUSD(res.usd)
    })
  }, [wallet, token])

  useEffect(() => {
    walletsApi.getWallets().then(setWallets)
  }, [])

  const handleSubmit = () => {
    if (submitting) return
    if (!wallet) return
    setSubmitting(true)
    contractsApi
      .deployToken(wallet, token.id)
      .then((created) => resolve(created))
      .catch(() => setSubmitting(false))
  }

  const estimateFormatted = estimatedGas ? `${bigintToNumber(estimatedGas, networkPrecision[network])} ${networkCurrencies[network]}` : '---'
  const usdEstimateFormatted = estimatedUSD ? `$${estimatedUSD.toFixed(2)}` : ''

  const networkWallets = wallets?.filter((_wallet) => _wallet.network === network)

  return (
    <Modal open={open} close={onCancel}>
      <div className="flex flex-col p-6 gap-6 rounded-3xl items-center w-[440px] max-w-full bg-content shadow-2xl backdrop-blur-[64px]">
        <h2 className="font-bold text-lg w-full text-center text-emerald-400">
          Deploy {token?.name} to {networkNames[network]}
        </h2>
        <div className="flex flex-col gap-4 w-full">
            <h2 className="font-bold text-lg w-full text-left text-emerald-400">Estimated fees</h2>
            <input type="text" readOnly value={estimateFormatted + (!!usdEstimateFormatted ? (` (${usdEstimateFormatted})`) : "")} className="input w-full truncate text-emerald-400" />
        </div>
        <div className="flex flex-col overflow-y-auto rounded-xl h-max gap-4 grow w-full">
            <h2 className="font-bold text-lg w-full text-left text-emerald-400">Wallet</h2>
          {networkWallets ? networkWallets.map((_wallet) => (
            <button key={_wallet.id} className={["flex gap-2 w-full btn-selector", wallet === _wallet.id ? "active" : ""].asClass} onClick={() => setWallet(_wallet.id)}>
              {_wallet.name}
            </button>
          )) : <Icons.NewTokenLoading className="w-6 h-6 animate-spin" />}
          </div>
          <div className="flex items-center gap-6">
        <button
          className="btn-secondary"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className="btn-primary"
          onClick={handleSubmit}
        >
          Deploy
        </button>
        </div>
      </div>
    </Modal>
  )
}

export default DeployTokenModal
