import { useState } from 'react'
import Modal from '../../../../components/Modal'
import { AwaitableModal } from '../../../../hooks/useAwaitableModal'
import { IWallet } from '../../../../api/wallets/types'
import { walletsApi } from '../../../../api/wallets'
import { Network } from '../../../../types/network'
import { availableNetworks, networkNames } from '../../../../constants/networks'

const AddNewWalletModal: AwaitableModal<IWallet, {}> = ({ resolve, onCancel, open }) => {
  const [name, setName] = useState('')
  const [network, setNetwork] = useState<Network>('eth')
  const [privateKey, setPrivateKey] = useState('')
  const [submitting, setSubmitting] = useState(false)

    const handleSubmit = () => {
        if (submitting) return
        if (!name) return
        setSubmitting(true)
        walletsApi.createWallet(network, name, privateKey).then((created) => resolve(created)).catch(() => setSubmitting(false))
    }

  return (
    <Modal open={open} close={onCancel}>
    <div className="flex flex-col p-6 gap-6 rounded-3xl items-center w-[440px] max-w-full bg-content shadow-2xl backdrop-blur-[64px]">
        <h2 className="font-bold text-xl w-full text-center text-emerald-500">Add a new wallet</h2>
        <div className="flex gap-6 h-min items-center w-full">
        <div className="flex flex-col gap-6 grow max-h-max">
          <input type="text" placeholder="Wallet name" className="input w-full" value={name} onChange={(e) => setName(e.target.value)} />
          <input type="text" placeholder="Private key (optional)" className="input w-full" value={privateKey} onChange={(e) => setPrivateKey(e.target.value)} />
        </div>
        <div className="flex flex-col overflow-y-auto rounded-xl h-max gap-2 grow">
          {availableNetworks.map((_network) => (
            <button key={_network} className={["flex gap-2 w-full btn-selector", network === _network ? "active" : ""].asClass} onClick={() => setNetwork(_network)}>
              {networkNames[_network]}
            </button>
          ))}
          </div>
          </div>
        <button className="btn-primary" onClick={handleSubmit}>
          Add
        </button>
      </div>
    </Modal>
  )
}

export default AddNewWalletModal
