import { toast } from 'react-toastify'

const toasts = {
  error: (message: string) => {
    return toast(
      <div className="flex items-center justify-center bg-[#ee002a70] backdrop-blur-2xl px-5 py-3 gap-4 rounded-full mx-auto max-w-max">
        <span className="text-xl font-medium text-white text-center font-bold tracking-wide">{message || 'An unknown error occurred'}</span>
      </div>,
      {
        className: '!m-0 toast-wrapper',
        position: toast.POSITION.BOTTOM_CENTER,
        closeButton: false,
        autoClose: 2000,
      }
    )
  },
  success: (message: string) => {
    return toast(
      <div className="flex items-center justify-center bg-emerald-400 backdrop-blur-2xl px-5 py-3 gap-4 rounded-full mx-auto max-w-max">
        <span className="text-xl font-medium text-white text-center font-bold tracking-wide">{message || 'An unknown error occurred'}</span>
      </div>,
      {
        className: '!m-0 toast-wrapper',
        position: toast.POSITION.BOTTOM_CENTER,
        closeButton: false,
        autoClose: 2000,
      }
    )
  },
}

export default toasts
