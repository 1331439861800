import { FC, useEffect } from 'react'
import { createPortal } from 'react-dom'

interface props {
  open: boolean
  close: (...args: any) => void
}

const modalCounterKey = "data-modals-mounted"

const Modal: FC<React.PropsWithChildren<props>> = ({ children, open, close }) => {
  useEffect(() => {
    if(!open) return
    const currentModalCount = document.body.getAttribute(modalCounterKey) || "0"
    document.body.setAttribute(modalCounterKey, (Number(currentModalCount) + 1).toString())

    return () => {
      const currentModalCount = document.body.getAttribute(modalCounterKey) || "0"
      const nextCount = Number(currentModalCount) - 1
      if (nextCount>0) {
        document.body.setAttribute(modalCounterKey, nextCount.toString())
      } else {
        document.body.removeAttribute(modalCounterKey)
      }
    }
  }, [open])
  if (!open) return null

  return createPortal(
    <div className="fixed inset-0 p-8 flex items-center justify-center z-[100000000] animate-fade-in modal" onClick={() => close()}>
      <div onClick={(e) => e.stopPropagation()}>{children}</div>
    </div>,
    document.body
  )
}

export default Modal
