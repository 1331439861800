import { FC } from "react";

const IntegrationContactPage: FC = () => {
    return (
        <div className="px-10 pb-10 -mt-4 text-white text-lg flex flex-col">
            <span className="text-2xl font-semibold mt-4">Error Response Format</span>
            <span className="text-lg font-normal leading-[200%] pl-3 mt-2">
                an error response will include a status code and a message, you can reference the code in the section below,
                malformed responses mean a server error
            </span>
            <span className="text-2xl font-semibold mt-2">Error Status Reference</span>
            <span className="text-lg font-normal leading-[200%] pl-3 mt-2">
                1 - server side error
                2 - input error
            </span>
        </div>
    )
}

export default IntegrationContactPage