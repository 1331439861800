import { FC } from "react";
import { Outlet, Route, Routes } from "react-router";
import MainLayout from "../layouts/MainLayout";
import WalletsPage from "../pages/app/Wallets";
import TokensPage from "../pages/app/Tokens";
import TokenPage from "../pages/app/Tokens/pages/Token";
import DashboardPage from "../pages/app/Dashboards";

const AuthorizedRouter: FC = () => {
    return (
        <Routes>
            <Route path="*" element={<MainLayout />}>
                <Route index element={<DashboardPage />} />
                <Route path="wallets" element={<WalletsPage />} />
                <Route path="tokens" element={<Outlet />}>
                    <Route index element={<TokensPage />} />
                    <Route path=":id" element={<TokenPage />} />
                </Route>
            </Route>
        </Routes>
    )
}

export default AuthorizedRouter