import { FC, PropsWithChildren, ReactNode, useState } from "react";
import titleContext from "./titleContext";

const TitleProvider: FC<PropsWithChildren> = ({children}) => {
    const [text, setText] = useState<string>()
    const [side, setSide] = useState<ReactNode>()
    return (
        <titleContext.Provider value={{text, setText, side, setSide}}>
            {children}
        </titleContext.Provider>
    )
}

export default TitleProvider