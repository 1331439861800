/* eslint-disable no-extend-native */
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'
import reportWebVitals from './reportWebVitals'
import Root from './Root'
import { BrowserRouter } from 'react-router-dom'
import AuthProvider from './contexts/Auth/AuthProvider'
import { BrowserProvider, Eip1193Provider } from 'ethers'
import TitleProvider from './contexts/Title/TitleProvider'
import { ToastContainer } from 'react-toastify'
import { createPortal } from 'react-dom'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const toastsRoot = document.getElementById('toasts') as HTMLElement

declare global {
  interface Window {
    ethereum: BrowserProvider & Eip1193Provider
  }

  interface Array<T> {
    asClass: string
  }
}

Object.defineProperty(Array.prototype, 'asClass', {
  configurable: true,
  get() {
    return this.filter(Boolean).join(' ')
  },
})

root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <TitleProvider>
          <Root />
        </TitleProvider>
      </BrowserRouter>
    </AuthProvider>
    {createPortal(
      <React.StrictMode>
        <ToastContainer position="bottom-right" hideProgressBar limit={1} />
      </React.StrictMode>,
      toastsRoot
    )}
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
