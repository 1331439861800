import { Dispatch, ReactNode, SetStateAction, createContext } from "react";

interface TitleContext {
    text: string | undefined
    setText: Dispatch<SetStateAction<string | undefined>>
    side: ReactNode | undefined
    setSide: Dispatch<SetStateAction<ReactNode | undefined>>
}

const titleContext = createContext<TitleContext>({} as TitleContext);

export default titleContext