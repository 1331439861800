import { FC, useEffect, useMemo, useState } from 'react'

import useTitle from '../../../contexts/Title/useTitle'
import Icons from '../../../assets/icons'
import { dashboardsApi } from '../../../api/dashboards'
import { ITransactionDashboard } from '../../../api/dashboards/types'
import ReactApexChart from 'react-apexcharts'

const days = 30

const DashboardPage: FC = () => {
  const [dashboardEntries, setDashboardEntries] = useState<ITransactionDashboard[]>()
  const [showGraphs, setShowGraphs] = useState(false)

  useTitle('Dashboard')

  useEffect(() => {
    dashboardsApi.getDashboard().then(setDashboardEntries)
  }, [])

  const dates = useMemo(() => {
    const date = new Date()
    date.setDate(date.getDate() + 1)

    const datesArray = []
    for (let i = 0; i < days; i++) {
      datesArray.push(new Date(date.setDate(date.getDate() - 1)).toISOString().split('T')[0])
    }
    return datesArray
  }, [])

  const dataByToken = useMemo(() => {
    const byToken =
      dashboardEntries?.reduce((acc, entry) => {
        acc[entry.token] = acc[entry.token] || Array(days).fill({})
        const dateIndex = dates.indexOf(entry.day)
        if (dateIndex === -1) return acc
        acc[entry.token][dateIndex] = entry

        return acc
      }, {} as Record<string, Partial<ITransactionDashboard>[]>) || {}

    return byToken
  }, [dates, dashboardEntries])

  const getSeriesFromSelector = (token: string | 'all', selector: keyof Omit<ITransactionDashboard, 'network' | 'token' | 'symbol' | 'day' | 'image'>, color: string) => {
    const x = dates
    const y = dates.map((date) => {
      if (token === 'all') return Object.values(dataByToken).reduce((acc, tokenData) => acc + (tokenData.find((entry) => entry.day === date)?.[selector] || 0), 0)
      const entry = dataByToken[token]?.find((entry) => entry.day === date)
      return entry?.[selector] || 0
    })

    return {
      name: token,
      x,
      y,
      color,
    }
  }

  if (!dashboardEntries)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Icons.NewTokenLoading className="animate-spin w-10 h-10 text-white" />
      </div>
    )

  const seriesOperation = (
    a1: ReturnType<typeof getSeriesFromSelector>,
    operation: (a: number, b: number) => number,
    a2: ReturnType<typeof getSeriesFromSelector>["y"],
  ) => {
    return {
      ...a1,
      y: a1.y.map((v, i) => operation(v, a2[i] || 0)),
    }
  }
  
  const burnAmountSeries = getSeriesFromSelector('all', 'burn', '#fef08a')
  const mintAmountSeries = getSeriesFromSelector('all', 'mint', '#a5f3fc')
  const totalAmountSeries = getSeriesFromSelector('all', 'volume', '#34d399')
  const avgFeeSeries = getSeriesFromSelector('all', 'avgFee', '#fb7185')
  const mintCountSeries = getSeriesFromSelector('all', 'mintCount', '#a5f3fc')
  const burnCountSeries = getSeriesFromSelector('all', 'burnCount', '#fef08a')
  const totalCountSeries = getSeriesFromSelector('all', 'count', '#34d399')

  const firstSeries = { name: 'Volume', series: totalAmountSeries }

  const series = [
    { name: 'Volume % Out', series: seriesOperation(mintAmountSeries, (a, b) => b === 0 ? 0: (a / b) * 100, totalAmountSeries.y) },
    { name: 'Volume % In', series: seriesOperation(burnAmountSeries, (a, b) => b === 0 ? 0 : (a / b) * 100, totalAmountSeries.y) },
    { name: 'Txns In', series: mintCountSeries },
    { name: 'Txns Out', series: burnCountSeries },
    { name: 'Tx Count', series: totalCountSeries },
    { name: 'Avg Fee', series: avgFeeSeries },
  ] as const

  return (
    <div className="flex flex-wrap gap-16 p-8 transition-all [&>*]:fade-in overflow-y-auto h-full" onAnimationEnd={() => setShowGraphs(true)}>
      <div className="w-full aspect-[16/6]">
         {showGraphs && (<ReactApexChart
          type="line"
          height="100%"
          series={[{ name: firstSeries.name, data: firstSeries.series.y.slice(0).reverse(), color: firstSeries.series.color }]}
          options={{
            chart: {
              width: '100%',
              height: '100%',
              toolbar: { show: false },
              zoom: { enabled: false },
              animations: { enabled: true, speed: 200 },
              offsetX: 0,
              offsetY: 0,
              sparkline: { enabled: true },
            },
            xaxis: {
              categories: dates.slice(0).reverse().map((d) => new Date(d).toLocaleDateString()),
              axisBorder: { show: false },
              axisTicks: { show: false },
              labels: { show: false },
            },
            fill: {
              opacity: [0, 1, 0],
              colors: [firstSeries.series.color],
              type: 'gradient',
              gradient: {
                shade: 'dark',
                shadeIntensity: 1,
                type: 'horizontal',
                opacityFrom: 0,
                stops: [0, 25, 90, 100],
              },
            },
            grid: {
              show: false,
              padding: {
                top: 0,
                bottom: 0,
              },
            },
            yaxis: { show: false },
            stroke: {
              width: 5,
              curve: 'smooth',
            },
          }}
        />)}
      </div>
      {series.map((s) => (
      <div className="grow bg-content p-4 rounded-lg min-w-[400px]">
        {showGraphs && (<ReactApexChart
          type="line"
          height="100%"
          series={[{ name: s.name, data: s.series.y.slice(0).reverse(), color: s.series.color }]}
          options={{
            chart: {
              width: '100%',
              height: '100%',
              toolbar: { show: false },
              zoom: { enabled: false },
              animations: { enabled: true, speed: 200 },
              offsetX: 0,
              offsetY: 0,
              sparkline: { enabled: true },
            },
            xaxis: {
              categories: dates.slice(0).reverse(),
              axisBorder: { show: false },
              axisTicks: { show: false },
              labels: { show: false },
            },
            fill: {
              opacity: [0, 1, 0],
              colors: [s.series.color],
              type: 'gradient',
              gradient: {
                shade: 'dark',
                shadeIntensity: 1,
                type: 'horizontal',
                opacityFrom: 0,
                stops: [0, 25, 90, 100],
              },
            },
            grid: {
              show: false,
              padding: {
                top: 0,
                bottom: 0,
              },
            },
            yaxis: { show: false },
            stroke: {
              width: 5,
              curve: 'smooth',
            },
          }}
        />)}
        </div>))}
    </div>
  )
}

export default DashboardPage
