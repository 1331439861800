import { FC, useEffect, useState } from 'react'
import { walletsApi } from '../../../api/wallets'
import { IWallet } from '../../../api/wallets/types'
import useAwaitableModal from '../../../hooks/useAwaitableModal'
import AddNewWalletModal from './modals/AddNewWallet'
import useTitle from '../../../contexts/Title/useTitle'
import Wallet from './components/Wallet'
import Icons from '../../../assets/icons'

const WalletsPage: FC = () => {
  const [wallets, setWallets] = useState<IWallet[]>()
  const [addNewWallet, AddNewWalletComponent] = useAwaitableModal(AddNewWalletModal, {})

  useTitle("Your Wallets")

  useEffect(() => {
    walletsApi.getWallets().then(setWallets)
  }, [])

  const onAdd = () => {
    addNewWallet().then(created => created && setWallets(old => old && [...old, created]))
  }

  if (!wallets) return (
    <div className='flex items-center justify-center w-full h-full'>
      <Icons.NewTokenLoading className='animate-spin w-10 h-10 text-white' />
    </div>
  )

  return (
    <div className="grid grid-cols-3 gap-8 p-8 transition-all h-max [&>*]:fade-in">
      <AddNewWalletComponent />
          {wallets?.map((wallet) => (
            <Wallet key={wallet.id} wallet={wallet} />
          ))}
        <div className="flex flex-col gap-2 p-4 rounded-2xl bg-content items-center justify-center cursor-pointer group min-h-[200px] group hover:bg-white-5 transition-all" onClick={onAdd}>
            <span className="w-10 h-10 bg-content items-center justify-center rounded-full transition-transform text-white flex text-xl pb-px group-hover:scale-[150%]">+</span>
        </div>
    </div>
  )
}

export default WalletsPage
