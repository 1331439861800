import { Network } from "../types/network"

export const getScanUrlForAddress = (network: Network, address: string) => {
    switch (network) {
        case "eth":
            switch (process.env.NODE_ENV) {
                case 'production':
                    return `https://etherscan.io/address/${address}`
                default:
                    return `https://sepolia.etherscan.io/address/${address}`
            }
        case "pol":
            switch (process.env.NODE_ENV) {
                case 'production':
                    return `https://polygonscan.com/address/${address}`
                default:
                    return `https://mumbai.polygonscan.com/address/${address}`
            }
    }
}

export const getScanUrlForTx = (network: Network, address: string) => {
    switch (network) {
        case "eth":
            switch (process.env.NODE_ENV) {
                case 'production':
                    return `https://etherscan.io/tx/${address}`
                default:
                    return `https://sepolia.etherscan.io/tx/${address}`
            }
        case "pol":
            switch (process.env.NODE_ENV) {
                case 'production':
                    return `https://polygonscan.com/tx/${address}`
                default:
                    return `https://mumbai.polygonscan.com/tx/${address}`
            }
    }
}

export const getScanUrlForToken = (network: Network, address: string) => {
    switch (network) {
        case "eth":
            switch (process.env.NODE_ENV) {
                case 'production':
                    return `https://etherscan.io/token/${address}`
                default:
                    return `https://sepolia.etherscan.io/token/${address}`
            }
        case "pol":
            switch (process.env.NODE_ENV) {
                case 'production':
                    return `https://polygonscan.com/token/${address}`
                default:
                    return `https://mumbai.polygonscan.com/token/${address}`
            }
    }
}