import {jwtDecode} from 'jwt-decode';
import { tokenKeys } from '../constants/tokens';

export const isTokenValid = (token: string | null) => {
    if (!token) {
        return false;
    }
    const decoded = jwtDecode(token);
    if (!decoded) {
        return false;
    }

    const now = Date.now().valueOf() / 1000;
    if (typeof decoded === 'string') {
        return false;
    }
    return decoded.exp && decoded.exp > now;
}

export const getToken = (token: keyof typeof tokenKeys) => {
    return localStorage.getItem(tokenKeys[token]);
};

export const setToken = (token: keyof typeof tokenKeys, value: string) => {
    localStorage.setItem(tokenKeys[token], value);
    const event = new CustomEvent('tokenChanged', { detail: { token, value } });
    window.dispatchEvent(event);
};

export const removeToken = (token: keyof typeof tokenKeys) => {
    localStorage.removeItem(tokenKeys[token]);
    const event = new CustomEvent('tokenChanged', { detail: { token, value: null } });
    
    window.dispatchEvent(event);
};