import {ReactComponent as Dashboard} from "./dashboard.svg"
import {ReactComponent as NewTokenLoading} from "./new_token_loading.svg"
import {ReactComponent as Picture} from "./picture.svg"
import {ReactComponent as Tokens} from "./tokens.svg"
import {ReactComponent as Wallets} from "./wallets.svg"
 
const Icons = {
    Dashboard,
    NewTokenLoading,
    Picture,
    Tokens,
    Wallets,
}

export default Icons
