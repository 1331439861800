import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { tokensApi } from '../../../../../api/tokens'
import { INetworkToken, IToken } from '../../../../../api/tokens/types'
import { availableNetworks, networkLogoSrcs, networkNames } from '../../../../../constants/networks'
import useAwaitableModal from '../../../../../hooks/useAwaitableModal'
import DeployTokenModal from './modals/DeployToken'
import useTitle from '../../../../../contexts/Title/useTitle'
import useSide from '../../../../../contexts/Title/useSide'
import DeveloperSettingsModal from './modals/DeveloperSettings'
import Icons from '../../../../../assets/icons'
import { getScanUrlForToken, getScanUrlForTx } from '../../../../../utils/scanUri'
import { Network } from '../../../../../types/network'
import DeployedDevSettingsModal from './modals/DeployedDevSettings'

const getNetworkToken = (token: IToken & { networks: INetworkToken[] }, network: string) => {
  return token?.networks.find((networkToken) => networkToken.network === network)
}

const TokenPage: FC = () => {
  const [token, setToken] = useState<IToken & { networks: INetworkToken[] }>()
  const [deploy, DeployTokenComponent] = useAwaitableModal(DeployTokenModal, {} as any)
  const [developerSettings, DeveloperSettingsComponent] = useAwaitableModal(DeveloperSettingsModal, {} as any)
  const [deployedDevSettings, DeployedDevSettingsComponent] = useAwaitableModal(DeployedDevSettingsModal, {} as any)
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const { id } = params as { id: string }
    tokensApi.getToken(id).then(setToken)
  }, [params])

  const getWalletName = (networkToken?: INetworkToken) => {
    if (typeof networkToken?.wallet === 'string') return networkToken?.wallet
    return networkToken?.wallet?.name || '---'
  }

  useTitle(token?.name || '')
  useSide(
    useMemo(
      () => (
        <div className="flex items-center gap-6 relative -right-20">
          <span className="text-lg text-emerald-400 font-bold">{token?.symbol}</span>
          <img src={token?.image} alt={token?.name} className="w-10 h-10 rounded-full" />
          <button
            onClick={() => developerSettings({ token }).then((updated) => updated && setToken((old) => ({ ...old, ...(updated as any) })))}
            className="bg-white-10 relative hover:bg-white-20 text-lg rounded-full w-10 h-10 flex items-center justify-center text-white"
          >
            &lt;/&gt;
            {!token?.notifyUrl && <div className="absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full" />}
          </button>
        </div>
      ),
      [token, developerSettings]
    )
  )

  const onDeploy = (network: string) => {
    deploy({ token, network }).then((updated) => updated && setToken((old) => old && { ...old, networks: [...old.networks, updated] }))
  }

  const openExplorer = (networkToken: INetworkToken) => {
    const url = getScanUrlForToken(networkToken.network as any, networkToken.address)
    if (!url) return
    window.open(url, '_blank')
  }

  const openUnconfirmedExplorer = (network: Network, txId: string) => {
    const url = getScanUrlForTx(network as any, txId)
    if (!url) return
    window.open(url, '_blank')
  }

  const goToWallet = (networkToken?: INetworkToken) => {
    if (!networkToken?.wallet) return
    let id = networkToken?.wallet
    if (typeof id !== 'string') {
      id = id.id
    }
    const url = `/app/wallets/${id}`
    navigate(url)
  }

  if (!token)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Icons.NewTokenLoading className="animate-spin w-10 h-10 text-white" />
      </div>
    )

  return (
    <div className="flex flex-col gap-8 p-8 pt-4 transition-all h-max fade-in">
      <DeployTokenComponent />
      <DeveloperSettingsComponent />
      <DeployedDevSettingsComponent />
      <table className="[&_td]:bg-content border-spacing-4 [&_td]:px-4 [&_td]:py-2 !border-separate [&_td]:text-lg [&_td]:text-white [&_th]:text-lg [&_th]:text-left [&_th]:text-emerald-400 [&_th]:px-4 [&_td]:px-4 [&_td]:rounded-xl">
        <thead>
          <tr className="">
            <th>Network</th>
            <th />
            <th>Contract Address</th>
            <th>Funding Wallet</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {availableNetworks.map((network) => {
            const networkToken = getNetworkToken(token as IToken & { networks: INetworkToken[] }, network)
            const state = networkToken?.txId && !networkToken.address ? 'deploying' : networkToken?.address ? 'deployed' : 'not-deployed'

            return (
              <tr key={network}>
                <td>{networkNames[network as keyof typeof networkNames]}</td>
                <td className="!w-12 !rounded-full !p-0">
                  <img src={networkLogoSrcs[network]} alt={network} className="w-12 h-12 rounded-full" />
                </td>
                <td className={state === 'deployed' ? 'truncate clickable-link' : undefined} onClick={state === 'deployed' ? () => openExplorer(networkToken as any) : undefined}>
                  {state === 'deploying' ? 'deploying' : networkToken?.address || '---'}
                </td>
                <td className={state !== 'not-deployed' ? 'clickable-link' : undefined} onClick={state !== 'not-deployed' ? () => goToWallet(networkToken) : undefined}>
                  <div className="flex gap-1 items-center">{getWalletName(networkToken)}</div>
                </td>
                <td className="!w-12 !h-12 !bg-transparent !p-0">
                  {state === 'not-deployed' ? (
                    <button className="bg-content hover:bg-white-10 cursor-pointer text-2xl rounded-full w-12 h-12 flex items-center justify-center" onClick={() => onDeploy(network)}>
                      &gt;
                    </button>
                  ) : state === 'deploying' ? (
                    <Icons.NewTokenLoading onClick={() => openUnconfirmedExplorer(network, networkToken?.txId as string)} className="w-12 h-12 animate-spin cursor-pointer" />
                  ) : (
                    <button
                      onClick={() => deployedDevSettings({ token, network }).then((updated) => updated && setToken((old) => ({ ...old, ...(updated as any) })))}
                      className="bg-content hover:bg-white-10 text-lg rounded-full w-12 h-12 flex items-center justify-center text-white"
                    >
                      &lt;/&gt;
                    </button>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TokenPage
