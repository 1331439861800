import { useContext, useEffect, useRef } from "react"
import titleContext from "./titleContext"

const useTitle = (title: string) => {
    const previous = useRef<string | undefined>()
    const {setText} = useContext(titleContext);
    useEffect(() => {
        setText(old => {
            previous.current = old
            return title
        })

        return () => {
            setText(previous.current)
        }
    }, [title, setText])
}

export default useTitle