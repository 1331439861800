import { FC } from "react";

const IntegrationOverviewPage: FC = () => {
    return (
        <div className="px-10 pb-10 -mt-4 text-white text-lg flex flex-col">
            <span className="text-xl font-bold">Deploying a new Token</span>
            <span className="text-lg font-normal leading-[200%] pl-3 mt-2">
                1. Go to <b>Your Tokens</b> and create your token<br/>
                2. Go to <b>Your Wallets</b> and add a new wallet that will be used for issuing the new created tokens. You can import a wallet or generate a new one.<br/>
                3. Go to <b>Your Tokens</b> and click on the token you created. Click on 'Deploy' for the network of your choosing and select the wallet you want to use for deploying and issuing the token from your wallets.<br/>
                4. Repeat <b>step 3</b> for all the networks you want your token to be deployed on.<br/> 
            </span>
            <span className="text-xl font-semibold mt-8">Simplified Currency Exchange Flow</span>
            <span className="text-2xl font-semibold mt-2 pl-2">In-App → Crypto</span>
            <span className="text-lg font-normal leading-[200%] pl-3 mt-2">
                1. User selects the amount of token they want to exchange, the network and gives their address<br/>
                2. Your server handles the request, calls the issue endpoint with the data and updates the user's balance.<br/>
                3. Funds are sent to the user's address.<br/>
            </span>
            <span className="text-2xl font-semibold mt-2">Crypto → In-App</span>
            <span className="text-lg font-normal leading-[200%] pl-3 mt-2">
                1. User burns amount of token using their address.<br/>
                2. Crydge notifies your server to update the user balance accordingly.<br/>
            </span>
        </div>
    )
}

export default IntegrationOverviewPage