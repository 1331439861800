import { FC } from "react";
import { Route, Routes } from "react-router";
import RegisterPage from "../pages/auth/Register";
import AuthPage from "../pages/auth/Auth";

const UnauthorizedRouter: FC = () => {
    return (
        <Routes>
            <Route index element={<AuthPage />} />
            <Route path="register" element={<RegisterPage />} />
        </Routes>
    )
}

export default UnauthorizedRouter