import { AwaitableModal } from '../../hooks/useAwaitableModal'
import Modal from '../Modal'

const AreYouSureModal: AwaitableModal<{}, { text?: string }> = ({ open, onCancel, resolve, initialData }) => {
  const text = initialData?.text

  return (
    <Modal open={open} close={onCancel}>
      <div className="flex flex-col p-6 gap-6 rounded-3xl items-center w-[440px] max-w-full bg-content shadow-2xl backdrop-blur-[64px]">
        <h2 className="font-bold text-xl text-emerald-400">Are You Sure?</h2>
        {text ? (
            <div className="flex flex-col col-span-3 gap-1 text-center w-full px-8">
              <span className="text-base text-emerald-400 font-medium text-center w-full">{text}</span>
          </div>
        ) : null}
        <div className="flex items-center gap-6 justify-between">
        <button className="btn-secondary" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn-primary" onClick={resolve}>
          Continue
        </button>
        </div>
      </div>
    </Modal>
  )
}

export default AreYouSureModal
