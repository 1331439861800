import { FC } from "react"
import { Outlet, useLocation, useNavigate } from "react-router"
import { Logo } from "../assets/logo"

const locationToTitle = (path: string) => {
  switch (path) {
    case "":
      return ""
    case "/overview":
      return "basics_overview"
    case "/bridge-token":
      return "bridging_a_token"
    case "/errors":
      return "handling_errors"
    case "/support":
      return "contacting_support"
    default:
      return ""
  }
}

const IntegrateLayout: FC = () => {
  const navigate = useNavigate()

  const to = (path: string) => navigate('/integrate'+path)

  const path = useLocation().pathname.split("/integrate")[1]

  const title = locationToTitle(path)

  return (
    <div className="flex flex-col min-h-full w-full bg-slate-900">
      <div className="flex bg-slate-950 z-[100] w-full items-center px-4 py-3">
        <div className="flex items-center gap-8 text-lg">
          <Logo height={36} className="cursor-pointer" onClick={() => navigate('/')} />
          <span className="text-emerald-500">integration</span>
        </div>
      </div>
      <div className="flex flex-col gap-4 p-10">
          <span className="w-full text-left text-2xl text-emerald-500 font-slim mb-2 -mt-2">
            &gt;&emsp;crydge <span className="text-emerald-400 font-extrabold">integrate</span>
          </span>
          <span className="w-full cursor-pointer text-left text-white text-2xl hover:before:content-['>'] text-white font-slim py-1 mx-3.5 hover:pl-1" onClick={() => to('/overview')}>
            &emsp; basics_<span className="font-extrabold">overview</span>
          </span>
          <span className="w-full cursor-pointer text-left text-white text-2xl hover:before:content-['>'] text-white font-slim py-1 mx-3.5 hover:pl-1" onClick={() => to('/bridge-token')}>
            &emsp; bridging_a_<span className="font-extrabold">token</span>
          </span>
          {/*<span className="w-full cursor-pointer text-left text-white text-2xl hover:before:content-['>'] text-white font-slim py-1 mx-3.5 hover:pl-1" onClick={() => to('/errors')}>
            &emsp; handling_<span className="font-extrabold">errors</span>
  </span>*/}
          {/*<span className="w-full cursor-pointer text-left text-white text-2xl hover:before:content-['>'] text-white font-slim py-1 mx-3.5 hover:pl-1" onClick={() => to('/support')}>
            &emsp; contacting_<span className="font-extrabold">support</span>
</span>*/}
          <span className="w-full text-left text-2xl text-emerald-500 font-slim mt-4">
            &gt;&emsp;
            {!!title ? (
            <span className="text-emerald-400">{title}</span>
            ) : (
            <span className="blink">
              _
            </span>
            )}
          </span>
        </div>
        <div className="grow">
      <Outlet />
      </div>
      <div className="text-slate-700 text-center py-4 bg-slate-950">
        {/*Designed by <span className="font-bold">Aneta Potaś</span> • Developed by <span className="font-bold">Martin Repka</span>
        <br />*/}
        <span className="text-sm">by</span>{' '}
        <a className="font-bold hover:underline cursor-pointer leading-[200%]" href="https://tomorrow.biz" target="_blank" rel="noreferrer">
          tomorrow.biz
        </a>
        <br />© 2023
      </div>
    </div>
  )
}

export default IntegrateLayout