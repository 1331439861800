import { useState, useEffect, FC, PropsWithChildren } from "react"
import AuthContext from "./authContext"
import { AuthUser } from "../../types/auth"
import {jwtDecode} from "jwt-decode"
import { getToken, isTokenValid } from "../../utils/tokens"

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<AuthUser | null>()

  useEffect(() => {
    const handleTokenChange = () => {
      const token = getToken("client")
      if (isTokenValid(token)) {
        const decoded = jwtDecode(token as string) as AuthUser
        setUser(decoded)
      } else {
        setUser(null)
      }
    }

    handleTokenChange()

    window.addEventListener("tokenChanged", handleTokenChange)

    return () => {
      window.removeEventListener("tokenChanged", handleTokenChange)
    }
  }, [])

  return (
    <AuthContext.Provider value={user}>
        {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider