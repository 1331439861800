import { INetworkToken, IToken } from '../../../../../../api/tokens/types'
import Modal from '../../../../../../components/Modal'
import { AwaitableModal } from '../../../../../../hooks/useAwaitableModal'
import { Network } from '../../../../../../types/network'
import { copyText } from '../../../../../../utils/copyText'
import { addToMetamask, hasEthereum } from '../../../../../../utils/metamask'
import metamaskLogo from "../../../../../../assets/images/metamask.png"

const getNetworkToken = (token: IToken & { networks: INetworkToken[] }, network: string) => {
  return token?.networks.find((networkToken) => networkToken.network === network)
}

const DeployedDevSettingsModal: AwaitableModal<undefined, {token: IToken & { networks: INetworkToken[] }, network: Network}> = ({ resolve, onCancel, open, initialData }) => {
  const token = initialData.token
  const network = initialData.network
  const networkToken = token ? getNetworkToken(token, network) : undefined
  const hasMetamask = hasEthereum()
  const onAddToMetamask = () => {
    if (!networkToken) return
    addToMetamask(networkToken?.network, token, networkToken?.address).then(console.log)
  }

  return (
    <Modal open={open} close={onCancel}>
      <div className="flex flex-col p-6 gap-6 rounded-3xl items-center w-[440px] max-w-full bg-content shadow-2xl backdrop-blur-[64px]">
        <h2 className="font-bold text-lg w-full text-center text-emerald-400">Deployment Settings</h2>
        <div className="flex w-full flex-col gap-6">
          <div className="flex flex-col justify-between items-center py-1 gap-4">
            <h2 className="font-bold text-lg w-full text-left text-emerald-400 px-4">Metamask</h2>
            <button className="btn-secondary !px-4 flex w-full justify-between" onClick={onAddToMetamask}><span>{hasMetamask ? "Add to Metamask" : "Download Metamask"}</span><img src={metamaskLogo} className='w-6 h-6' /></button>
          </div>
        </div>
          <button className="btn-primary" onClick={() => resolve()}>
            Done
          </button>
      </div>
    </Modal>
  )
}

export default DeployedDevSettingsModal
