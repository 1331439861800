import { Api } from "..";
import { INetworkToken } from "./types";

class ContractsApi extends Api {
    async estimateFees(wallet: string, token: string) {
        const sp = new URLSearchParams();
        sp.set('wallet', wallet);
        sp.set('token', token);
        return this.api.get('/contracts/fees?'+sp.toString()).then(res => res.data.estimate as {native: string, usd: number})
    }

    async deployToken(wallet: string, token: string) {
        return this.api.post('/contracts?', {wallet, token}).then(res => res.data as INetworkToken)
    }
}

export const contractsApi = new ContractsApi();